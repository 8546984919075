var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("footer", { staticClass: "footer" }, [
    _c("img", {
      staticClass: "cat-img",
      attrs: { src: require("@/assets/img/icon/cat.png"), alt: "cat" },
    }),
    _c("ul", [
      _vm._m(0),
      _vm._m(1),
      _c("li", [
        _c(
          "a",
          { staticClass: "link", attrs: { href: _vm.help, target: "blank" } },
          [_vm._v("ヘルプ")]
        ),
      ]),
      _c(
        "li",
        [
          _c(
            "router-link",
            { staticClass: "link", attrs: { to: "/contact" } },
            [_vm._v("お問い合わせ")]
          ),
        ],
        1
      ),
    ]),
    _c("small", { staticClass: "small" }, [_vm._v("© 2018 indent")]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c(
        "a",
        { staticClass: "link", attrs: { href: "/terms", target: "blank" } },
        [_vm._v("利用規約")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", [
      _c(
        "a",
        { staticClass: "link", attrs: { href: "/privacy", target: "blank" } },
        [_vm._v("プライバシーポリシー")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }